import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ChakraProvider } from '@chakra-ui/react'

import App from 'App'

import theme from 'assets/Theme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language='hu'>
			<ChakraProvider theme={theme}>
				<App />
			</ChakraProvider>
		</GoogleReCaptchaProvider>
	</React.StrictMode>
)

import { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
	createBrowserRouter,
	RouterProvider
} from 'react-router-dom'
import {
	Container,
	Flex,
	Text,
} from '@chakra-ui/react'

import LandingPage from 'components/LandingPage'
import EventSubmitForm from 'components/EventSubmitForm'
import NotFoundPage from 'components/NotFoundPage'

const App = () => {
	const [isSubmitted, setSubmitted] = useState(false)
	const [isFormStarted, setFormStarted] = useState(false)

	const { executeRecaptcha } = useGoogleReCaptcha()

	// Prevent accidental page refresh or close
	useEffect(() => {
		if (!isSubmitted && isFormStarted) window.onbeforeunload = () => true
		else window.onbeforeunload = undefined
	}, [isSubmitted, isFormStarted])

	const BrowserRouter = createBrowserRouter([
		{
			path: '/',
			element: <LandingPage />
		},
		{
			path: '/submit',
			element:
				<EventSubmitForm
					setSubmitted={setSubmitted}
					setFormStarted={setFormStarted}
					executeRecaptcha={executeRecaptcha}
				/>
		},
		{
			path: '*',
			element: <NotFoundPage />
		}
	])

	return (
		<Container maxW='container.sm'>
			<Flex
				direction='column'
				align='center'
				height='100vh'
				pt={4}
			>
				<Flex
					grow={1}
					shrink={0}
					basis='auto'
					direction='column'
				>
					<RouterProvider router={BrowserRouter} />
				</Flex>
				<Flex
					shrink={0}
					pt={8}
					pb={4}
				>
					<Text fontSize='xs' align='center'>
						All rights reserved © SPOT Fotókör {new Date().getFullYear()}
					</Text>
				</Flex>
			</Flex>
		</Container >
	)
}

export default App

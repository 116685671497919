import { useCallback } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import {
	Box,
	Heading,
	Link,
	Text,
} from '@chakra-ui/react'

import { LinkIcon } from 'components/Icons'
import FormFields, { FIELD_TYPES } from 'components/FormFields'


const fields = [
	{
		id: 'title',
		label: 'Rendezvény neve',
		type: FIELD_TYPES.TEXT_FIELD,
		required: true,
		helperText: 'A rendezvény neve, amit az albumban is látni szeretnél.',
		validation: Yup
			.string()
			.required('Rendezvény neve kötelező'),
	},
	{
		id: 'startTime',
		label: 'Rendezvény kezdete',
		type: FIELD_TYPES.DATE_TIME_PICKER,
		required: true,
		helperText: 'A rendezvény kezdete, amikortól a fotózást szeretnéd.',
		validation: Yup
			.date('Nem megfelelő dátum')
			.min(new Date(), 'A dátum nem lehet múltbeli')
			.required('Rendezvény kezdete kötelező'),
	},
	{
		id: 'endTime',
		label: 'Rendezvény vége',
		type: FIELD_TYPES.DATE_TIME_PICKER,
		required: true,
		helperText: 'A rendezvény vége, ameddig a fotózást szeretnéd.',
		validation: Yup
			.date('Nem megfelelő dátum')
			.min(Yup.ref('startTime'), 'A rendezvény vége nem lehet korábbi, mint a kezdete.')
			.required('Rendezvény vége kötelező'),
	},
	{
		id: 'location',
		label: 'Rendezvény pontos helyszíne',
		type: FIELD_TYPES.TEXT_FIELD,
		required: true,
		helperText: 'A rendezvény pontos helyszíne, ahol a fotózás történni fog.',
		validation: Yup
			.string()
			.required('Rendezvény helyszíne kötelező'),
	},
	{
		id: 'description',
		label: 'Rendezvény menetrendje',
		type: FIELD_TYPES.MULTILINE_TEXT_FIELD,
		required: true,
		helperText: 'A rendezvény menetrendjét kérjük, hogy a lehető legpontosabban tüntesd fel, hogy a fotósok minél jobban fel tudjanak készülni a rendezvényre.',
		validation: Yup
			.string()
			.required('Rendezvény menetrendje kötelező'),
	},
	{
		id: 'peopleCount',
		label: 'Várható résztvevők száma',
		type: FIELD_TYPES.NUMBER_FIELD,
		required: true,
		helperText: 'A várható résztvevők száma, hogy a fotósok tudják, milyen méretű rendezvényre készüljenek.',
		min: 1,
		validation: Yup
			.number('Csak számokat írj be')
			.required('Részvevők száma kötelező'),
	},
	{
		id: 'name',
		label: 'Kapcsolattartó neve',
		type: FIELD_TYPES.TEXT_FIELD,
		required: true,
		helperText: 'A kapcsolattartó neve, aki a fotósokkal tartja a kapcsolatot.',
		validation: Yup
			.string()
			.required('Kapcsolattartó neve kötelező'),
	},
	{
		id: 'email',
		label: 'Kapcsolattartó e-mail címe',
		type: FIELD_TYPES.TEXT_FIELD,
		required: true,
		helperText: 'A kapcsolattartó e-mail címe, aki a fotósokkal tartja a kapcsolatot.',
		validation: Yup
			.string()
			.email('Nem megfelelő e-mail cím')
			.required('Kapcsolattartó e-mail címe kötelező'),
	},
	{
		id: 'phone',
		label: 'Kapcsolattartó telefonszáma',
		type: FIELD_TYPES.TEXT_FIELD,
		required: true,
		helperText: 'A kapcsolattartó telefonszáma, aki a fotósokkal tartja a kapcsolatot. Kérjük, hogy a telefonszámot a következő formátumban add meg: +36301234567',
		validation: Yup
			.string()
			.matches(/^\+\d{9,13}$/g, 'Nem megfelelő telefonszám')
			.required('Kapcsolattartó telefonszáma kötelező'),
	},
	{
		id: 'comment',
		label: 'Megjegyzés',
		type: FIELD_TYPES.MULTILINE_TEXT_FIELD,
		required: false,
		helperText: 'Ha bármi egyéb információval rendelkezel, amit fontosnak tartasz, de nem szerepel a fenti mezőkben, kérjük, hogy itt tüntesd fel.',
		validation: Yup
			.string(),
	},
]


const EventSubmitForm = ({ setFormStarted, setSubmitted, executeRecaptcha }) => {
	const handleSubmit = useCallback(async (values, successCallback, errorCallback) => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available')
			errorCallback()
			return
		}

		const token = await executeRecaptcha('submit')
		const body = {
			...values,
			'g-recaptcha-response': token,
			'source': window.location.hostname,
		}

		axios.post(process.env.REACT_APP_API_SUBMIT_URL, body)
			.then(successCallback)
			.catch(errorCallback)
	}, [executeRecaptcha])

	return (
		<>
			<Box
				p={4}
				pt={6}
				borderWidth='1px'
				borderRadius='lg'
				bg='white'
				position='relative'
			>
				<div
					style={{
						width: '100%',
						height: '10px',
						position: 'absolute',
						top: 0,
						left: 0,
						backgroundColor: 'rgb(28, 130, 113)',
						borderTopLeftRadius: '4px',
						borderTopRightRadius: '4px',
					}}
				/>
				<Heading
					as='h1'
					size='xl'
					mb={4}
					align='center'
				>
					SPOT - Rendezvénybejelentő
				</Heading>
				<Text
					mb={4}
					align='justify'
					style={{ hyphens: 'auto' }}
				>
					A <b>SPOT Fotókör</b> ezen az oldalon, illetve a <Link href='https://bejelentes.sch.bme.hu' color='blue' isExternal>https://bejelentes.sch.bme.hu<LinkIcon /></Link> oldalon kérhető fel rendezvényfotózásra.&nbsp;
				</Text>
				<Text
					mb={4}
					align='justify'
					style={{ hyphens: 'auto' }}
				>
					<b>Minimum 8 nappal</b> a rendezvény előtt legyen elküldve a bejelentő és <b>elég egyszer</b> elküldeni erről a felületről.
				</Text>
				<Text
					mb={4}
					align='justify'
					style={{ hyphens: 'auto' }}
				>
					A felkérésről <b>automatikus visszaigazoló e-mailt</b> küldünk.
				</Text>
			</Box>
			<FormFields
				fields={fields}
				onSubmit={handleSubmit}
				setSubmitted={setSubmitted}
				setFormStarted={setFormStarted}
			/>
		</>
	)
}

export default EventSubmitForm

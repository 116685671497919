import {
	Box,
	Image
} from '@chakra-ui/react'

const NotFoundPage = () => (
	<Box
		p={2}
		borderWidth='1px'
		borderRadius='lg'
		bg='white'
		position='relative'
	>
		<Image
			src='https://http.cat/images/404.jpg'
			alt='404'
			width='100%'
			height='auto'
		/>
	</Box>
)

export default NotFoundPage
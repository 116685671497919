import { useState, useEffect } from 'react'
import {
	Formik,
	Form,
	Field,
	useFormikContext,
} from 'formik'
import * as Yup from 'yup'
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	Input,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Textarea,
	useDisclosure
} from '@chakra-ui/react'
import {
	EmailIcon
} from '@chakra-ui/icons'

import ResponseDialog from 'components/ResponseDialog'

export const FIELD_TYPES = {
	TEXT_FIELD: 'text',
	NUMBER_FIELD: 'number',
	DATE_TIME_PICKER: 'date-time',
	MULTILINE_TEXT_FIELD: 'multiline-text',
}


const InputField = ({ data, field, form }) => {
	switch (data.type) {
		case FIELD_TYPES.TEXT_FIELD:
			return (
				<Input
					{...field}
					placeholder={data.placeholder}
					type='text'
				/>
			)

		case FIELD_TYPES.NUMBER_FIELD:
			return (
				<NumberInput
					{...field}
					onChange={val => form.setFieldValue(data.id, val)}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			)

		case FIELD_TYPES.DATE_TIME_PICKER:
			return (
				<Input
					{...field}
					placeholder={data.placeholder}
					type='datetime-local'
				/>
			)

		case FIELD_TYPES.MULTILINE_TEXT_FIELD:
			return (
				<Textarea
					{...field}
					placeholder={data.placeholder}
				/>
			)

		default:
			return null
	}
}

const FormFields = ({ fields, onSubmit, setSubmitted, setFormStarted }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [success, setSuccess] = useState(false)
	const [message, setMessage] = useState('')

	if (typeof setSubmitted !== 'function') {
		setSubmitted = () => { }
	}

	if (typeof setFormStarted !== 'function') {
		setFormStarted = () => { }
	}

	const initialValues = {}
	fields.forEach(field => {
		if (typeof initialValues[field.id] !== 'undefined') {
			return
		}

		switch (field.type) {
			case FIELD_TYPES.TEXT_FIELD:
			case FIELD_TYPES.NUMBER_FIELD:
			case FIELD_TYPES.DATE_TIME_PICKER:
			case FIELD_TYPES.MULTILINE_TEXT_FIELD:
				initialValues[field.id] = field.initValue || ''
				break
			default:
				break
		}
	})

	const ValueTracker = () => {
		const { values } = useFormikContext()
		useEffect(() => {
			setFormStarted(Object.values(values).some(value => value !== ''))
		}, [values])
		return null
	}

	const handleSubmit = (values, { setSubmitting, resetForm }) => {
		const newValues = { ...values }
		fields.forEach(field => {
			switch (field.type) {
				case FIELD_TYPES.NUMBER_FIELD:
					newValues[field.id] = parseInt(newValues[field.id])
					break
				case FIELD_TYPES.DATE_TIME_PICKER:
					newValues[field.id] = new Date(newValues[field.id]).toISOString()
					break
				default:
					break
			}
		})

		setSubmitting(true)
		onSubmit(newValues, (res) => {
			setSubmitting(false)

			onOpen()
			setSuccess(true)

			setSubmitted(true)
			resetForm({ values: initialValues })
		}, (err) => {
			setSubmitting(false)
			console.log(err)

			// Axios errors
			switch (err.code) {
				case 'ECONNABORTED':
				case 'ETIMEDOUT':
					setMessage('A kérés túllépte az időkorlátot.')
					break
				case 'ERR_NETWORK':
					setMessage('Hálózati hiba történt.')
					break
				default:
					setMessage(err.response?.data?.error)
					break
			}
			onOpen()
			setSuccess(false)
		})
	}

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					...fields.reduce((acc, field) => ({ ...acc, [field.id]: field.validation }), {})
				})}
				onSubmit={handleSubmit}
			>
				{(props) => (
					<Form sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
						{fields.map((data, index) => (
							<Box
								key={index}
								my={2}
								p={4}
								borderWidth='1px'
								borderRadius='lg'
								bg='white'
							>
								<Field name={data.id}>
									{({ field, form }) => (
										<FormControl isInvalid={form.errors[data.id] && form.touched[data.id]}>
											<FormLabel>{data.label + (data.required ? ' *' : '')}</FormLabel>
											<InputField data={data} field={field} form={form} />
											{form.errors[data.id] && form.touched[data.id] ? (
												<FormErrorMessage>{form.errors[data.id]}</FormErrorMessage>
											) : (
												<FormHelperText style={{ hyphens: 'auto' }}>{data.helperText}</FormHelperText>
											)}
										</FormControl>
									)}
								</Field>
							</Box>
						))}
						<Button
							leftIcon={<EmailIcon />}
							colorScheme='teal'
							variant='solid'
							type='submit'
							isLoading={props.isSubmitting}
							isDisabled={props.isSubmitting || !props.dirty}
							mt={4}
						>
							Beküldés
						</Button>
						<ValueTracker />
					</Form>
				)}
			</Formik>
			<ResponseDialog
				isOpen={isOpen}
				onClose={onClose}
				success={success}
				message={message}
			/>
		</>
	)
}

export default FormFields

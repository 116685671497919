import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Code
} from '@chakra-ui/react'

const ResponseDialog = ({ success, message, isOpen, onClose }) => {
    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {success ?
                            'Sikeres rendezvény bejelentés ✅' :
                            '❌ Sikertelen rendezvény bejelentés ❌'}
                    </AlertDialogHeader>

                    {success ? (
                        <AlertDialogBody style={{ hyphens: 'auto' }}>
                            A rendezvény bejelentése sikeresen megtörtént. A megadott e-mail címre elküldtük a bejelentés visszaigazolását.
                        </AlertDialogBody>
                    ) : (
                        <AlertDialogBody style={{ hyphens: 'auto' }}>
                            <Code colorScheme='red'>{message || 'Ismeretlen hiba történt.'}</Code><br />
                            Kérlrjük, próbáld újra a rendezvény bejelentését.
                        </AlertDialogBody>
                    )}

                    <AlertDialogFooter>
                        {success ? (
                            <Button onClick={onClose}>
                                Bezárás
                            </Button>
                        ) : (
                            <Button onClick={onClose}>
                                Újrapróbálkozás
                            </Button>
                        )}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default ResponseDialog
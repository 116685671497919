import {
	Box,
	Button,
	Heading,
	Link,
	Text,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { LinkIcon } from 'components/Icons'

const LandingPage = () => (
	<>
		<Box
			p={4}
			pt={6}
			borderWidth='1px'
			borderRadius='lg'
			bg='white'
			position='relative'
		>
			<div
				style={{
					width: '100%',
					height: '10px',
					position: 'absolute',
					top: 0,
					left: 0,
					backgroundColor: 'rgb(28, 130, 113)',
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px',
				}}
			/>
			<Heading
				as='h1'
				size='xl'
				mb={4}
				align='center'
			>
				SPOT - Rendezvénybejelentő
			</Heading>
			<Text
				mb={4}
				align='justify'
				style={{ hyphens: 'auto' }}
			>
				A <b>SPOT Fotókör</b> ezen az oldalon, illetve a <Link href='https://bejelentes.sch.bme.hu' color='blue' isExternal>https://bejelentes.sch.bme.hu<LinkIcon /></Link> oldalon kérhető fel rendezvényfotózásra.&nbsp;
			</Text>
			<Text
				mb={4}
				align='justify'
				style={{ hyphens: 'auto' }}
			>
				A felkérés mikéntjéről és a rendezvényfotózásról bővebb információ a <Link href='https://spot.sch.bme.hu/faq' color='blue' isExternal>GYIK<LinkIcon /></Link> oldalon található.
			</Text>
		</Box>
		<Button
			as={RouterLink}
			to='/submit'
			mt={4}
			colorScheme='teal'
			size='lg'
			width='100%'
		>
			Rendezvény bejelentése
		</Button>
	</>
)

export default LandingPage